<template>
  <div
    class="popup_wrap"
    style="width:650px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX050P170.001') }}</h1><!-- 첨부파일 확인 -->
      <div class="content_box">
        <h2 class="content_title">{{ $t('msg.ONEX050P170.002') }}</h2><!-- B/L 첨부 파일 -->
        <DEXT5Upload
          ref="dextupload"
          id="dextupload1"
          width="100%"
          height="200px"
          :category="true"
          :file-info="params.uploadFileInfos"
          :category-info="categoryList"
          :button-bar-edit="btnDownYn"
        />
      </div>
      <div v-if="!btnDownYn" class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="insertFile()"
        >{{ $t('msg.ONEX050P170.003') }}</a><!-- 저장 -->
      </div>
      <div v-else class="mt10 text_center">
        <a class="button blue lg" @click="$emit('close')">{{ $t('msg.NEWB010P040.001') }}</a><!-- 닫기 -->
      </div>
    </div>
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import blAttach from '@/api/rest/trans/blAttach'
import DEXT5Upload from '@/components/DEXT5Upload'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'BLAttachPop',
  components: { DEXT5Upload },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          type: '',
          blNo: '',
          podCtrCd: '',
          uploadFileList: [],
          btnDownYn: false
        }
      }
    }
  },
  data () {
    return {
      // 팝업에 보여줄 카테고리 리스트
      categoryList: [],
      // 첨부 구분 카테고리 (Rider, 면장, DG-Certi, VGM-Certi)
      //code collection CS046 ETC가 04로 되어 있음
      attachCategory: {
        rider: { category: this.$t('msg.ONEX050P170.005'), value: '03' },
        certiReport: { category: this.$t('msg.ONEX050P170.006'), value: '02' },
        dgCerti: { category: this.$t('msg.ONEX050P170.007'), value: '06' },
        vgmCerti: { category: this.$t('msg.ONEX050P170.008'), value: '20' },
        etc: { category: this.$t('msg.ONEX050P170.013'), value: '04' }
      },
      // 이란 업로드 카테고리 (LOI, 비금지 확인서, 최종용도확약서)
      iranCategory: {
        loi: { category: 'LOI', value: 'iran_01' },
        comfirm: { category: 'COMFIRMATION', value: 'iran_02' },
        assurance: { category: 'CERTIFICATE OFEND-USE ASSURANCE', value: 'iran_03' }
      },

      params: {
        blNo: '',
        type: '',
        podCtrCd: '',
        // 첨부파일 리스트(실제 입력)
        uploadFileInfos: []
      },
      btnDownYn: false
      // Dext Uploader와 연결되어 있는 배열
      // uploadFileList: []
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this._ = require('lodash')
    this.params = { ...this.params, ...this.parentInfo }
    this.params.uploadFileInfos = this._.cloneDeep(this.parentInfo.uploadFileList)
    this.btnDownYn = this.parentInfo.btnDownYn
    this.initPopup()
  },
  mounted () {
    // this.initPopup()
  },
  methods: {
    async initPopup () {
      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      const ctrCd = this.memberDetail.userCtrCd

      // await Promise.all([
      //   this.settingCategory(),
      //   this.getBLAttachList(),
      //   this.getBLAttachLoiUploadList(),
      //   this.getIranUploadList()
      // ])

      const arrPromise = [
        this.settingCategory()
        // this.getBLAttachList()
      ]

      // if (ctrCd === 'KR' && this.params.type === 'BL') {
      //   arrPromise.push(this.getBLAttachLoiUploadList())
      // }
      // if (this.params.podCtrCd === 'IR') {
      //   arrPromise.push(this.getIranUploadList())
      // }

      await Promise.all(arrPromise)
    },
    // ====================================================================
    // *************************** 카테고리 세팅 ***************************
    // ====================================================================
    // * SR 일때
    // - lang 'KOR': Rider, 면장, DG certi
    // - usrCtrCd 'KR': vgm certi
    // - 도착지 이란: Rider, DG certi, LOI
    // - 나머지: Rider, DG certi
    // * BL 일때
    // - lang 'KOR' :  Rider, 면장, DG certi
    // - usrCtrCd 'KR': vgm certi, [LOI]
    // - 도착지 이란:  Rider, DG certi, LOI, [비금지 확인서, 최종용도확약서]
    // - 나머지: Rider, DG certi
    // ====================================================================
    //async settingCategory () {
    settingCategory () {
      const lang = this.auth.serviceLang
      const ctrCd = this.memberDetail.userCtrCd

      // test
      // let lang = this.auth.serviceLang
      // let ctrCd = this.memberDetail.userCtrCd
      // lang = 'CHN'
      // ctrCd = 'CN'

      // Default
      this.categoryList.push(this.attachCategory.rider)
      this.categoryList.push(this.attachCategory.dgCerti)

      if (lang === 'KOR') {
        //this.categoryList.push(this.attachCategory.certiReport)
      }
      if (ctrCd === 'KR') {
        this.categoryList.push(this.attachCategory.certiReport)
        this.categoryList.push(this.attachCategory.vgmCerti)
        this.categoryList.push(this.attachCategory.etc)
        // 220719 첨부파일에서 loi 제외
        // if (this.params.type === 'BL') {
        //   await this.initCommCodes()
        // }
      }
      if (this.params.podCtrCd === 'IR') {
        this.categoryList.push(this.iranCategory.loi)
        if (this.params.type === 'BL') {
          this.categoryList.push(this.iranCategory.comfirm)
          this.categoryList.push(this.iranCategory.assurance)
        }
      }
    },
    // LOI 카테고리 호출(제 3국 SHPR LOI, 중량초과각서, DOP 선적 LOI, ETC)
    async initCommCodes () {
      const cdId = ['CS046']
      const tempCommonCodes = await commons.getCommonsCodesCommon(cdId)

      // console.log('tempCommonCodes')
      // console.log(tempCommonCodes.CS046)

      if (tempCommonCodes.CS046 !== undefined && tempCommonCodes.CS046.length > 0) {
        tempCommonCodes.CS046.forEach(code => {
          this.categoryList.push(
            { category: code.cdNm, value: 'loi_' + code.cd }
          )
        })
      }
    },
    // 파일 조회
    // async getBLAttachList () {
    //   await blAttach.getBLAttachList(this.params).then(response => {
    //     if (response.headers.respcode === 'C0000') {
    //       let fileList = response.data.blAttachList
    //       // console.log('getBLAttachList >>> fileList')
    //       // console.log(fileList)

    //       if (fileList !== undefined) {
    //         fileList.forEach(file => {
    //           this.uploadFileList.push(
    //             {
    //               guid: file.blRecvDocKndCd + '_' + file.fileSeq,
    //               originalName: file.fileNm,
    //               size: file.fileSz,
    //               fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?blNo=' + this.params.blNo + '&blRecvDocKndCd=' + file.blRecvDocKndCd + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH',
    //               category: file.blRecvDocKndCd,

    //               blNo: this.params.blNo,
    //               blRecvDocKndCd: file.blRecvDocKndCd,
    //               fileSeq: file.fileSeq
    //             }
    //           )
    //         })
    //       }
    //     }
    //   })
    // },
    // 파일 조회(LOI-UPLOAD)
    // async getBLAttachLoiUploadList () {
    //   await blAttach.getBLAttachLoiUploadList(this.params.blNo).then(response => {
    //     if (response.headers.respcode === 'C0000') {
    //       let fileList = response.data.blAttachList
    //       // console.log('getBLAttachLoiUploadList >>> fileList')
    //       // console.log(fileList)

    //       if (fileList !== undefined) {
    //         fileList.forEach(file => {
    //           this.uploadFileList.push(
    //             {
    //               guid: file.reqCatCd + '_' + file.reqSeq + '_' + file.fileSeq,
    //               originalName: file.fileNm,
    //               size: file.fileSz,
    //               fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + this.params.blNo + '&reqCatCd=' + file.reqCatCd + '&reqSeq=' + file.reqSeq + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH_LOI_UPLOAD',
    //               category: 'loi_' + file.reqCatCd,

    //               reqRno: this.params.blNo,
    //               reqCatCd: file.reqCatCd,
    //               reqSeq: file.reqSeq,
    //               fileSeq: file.fileSeq
    //             }
    //           )
    //         })
    //       }
    //     }
    //   })
    // },
    // 이란 업로드 파일 조회
    // async getIranUploadList () {
    //   const params = {
    //     reqRno: this.params.blNo
    //   }

    //   await trans.getIranUpload(params).then(response => {
    //     if (response.headers.respcode === 'C0000') {
    //       let fileList = response.data.iranUploadList

    //       if (fileList !== undefined) {
    //         fileList.forEach(file => {
    //           this.uploadFileList.push(
    //             {
    //               guid: file.bkgNo + '_' + file.fileSeq,
    //               originalName: file.fileNm,
    //               size: file.fileSz,
    //               fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + file.bkgNo + '&atchFileSeq=' + file.fileSeq + '&fileTypCd=' + file.fileTypCd + '&type=IRAN_UPLOAD',
    //               category: 'iran_' + file.fileTypCd,

    //               bkgNo: file.bkgNo,
    //               fileSeq: file.fileSeq,
    //               fileTypCd: file.fileTypCd
    //             }
    //           )
    //         })
    //       }
    //     }
    //   })
    // },
    validationCategory () {
      let isOk = true

      // 파일리스트 카테고리 선택 여부 검사
      // this.parentInfo.uploadFileList.forEach(element => {
      this.params.uploadFileInfos.forEach(element => {
        if (element.category === undefined || element.category === '') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P170.009')) // 카테고리가 선택되지 않은 파일이 있습니다.
          isOk = false
          return false
        }
      })

      return isOk
    },
    // 저장 or 수정
    async insertFile () {
      const TH = this
      // 저장하시겠습니까
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: TH.$t('msg.ONEX050P170.010'), useConfirmBtn: true })) {
        await TH.onConfirmInsert()
      }
    },
    // 저장 Confirm '확인' 클릭 시 콜백 메소드
    async onConfirmInsert () {
      if (this.validationCategory()) {
        // 상위 컴포넌트에서 가져온 데이터를 실제 입력할 parameter에 담는다.
        // this.params = { ...this.params, ...this.parentInfo }
        // 실제 입력할 파일리스트를 담는다.
        // this.params.uploadFileInfos = this.parentInfo.uploadFileList

        // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
        this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

        // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
        // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
        this.params.uploadFileInfos.forEach(element => {
          if (element.newFlag === undefined || element.newFlag === '') {
            element.fileId = ''
          }
        })

        // 파일 저장
        await blAttach.popBLAttachUpload(this.params).then(response => {
          // console.log(response)
          if (response.headers.respcode === 'C0000') {
            const TH = this
            // 저장되었습니다.
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.ONEX050P170.011'), function () {
              TH.$emit('selectFunc')
              TH.$emit('close')
            })
          } else {
            // 저장에 실패하였습니다. 관리자에게 문의해주시기 바랍니다.
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050P170.012'))
          }
        })
      }
    }
  }
}
</script>
